module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-63FWMJCZ9P","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Omc1lBageorFaL6k47JFD10K6dxeqcRy","devKey":"t38tcniqsZ15OeWjXTw3C2DuMjfXpQmL","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":false,"trackPageOnRouteUpdate":false,"trackPageOnRouteUpdateDelay":50,"trackPageWithTitle":false,"delayLoad":false,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0,"manualLoad":false,"host":"https://cdn.segment.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
